import axios from 'axios'
import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'
export function uploadAPK(data) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  return {
    API: request({
      url: '/api/console/appfile',
      method: 'POST',
      data: getFormData(data),
      cancelToken: source.token,
      timeout: -1,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
    source
  }
}

export function getAppFileList(params) {
  return request({
    url: '/api/console/appfile',
    method: 'GET',
    params: params
  })
}

export function setAppVisible(id, params) {
  return request({
    url: `/api/console/appfile/${id}`,
    method: 'GET',
    params: params
  })
}

export function setAppRemark(id, remarkText) {
  return request({
    url: `/api/console/appfile/${id}`,
    method: 'POST',
    data: getFormData({ remark: remarkText })
  })
}
