<template>
  <div id="OnlineOfficial">
    <el-card
      class="text-right"
      shadow="never"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form
            :inline="true"
            label-width="80px"
          >
            <el-form-item>
              <el-button
                type="primary"
                @click="handleUploadClick"
              >上传<i class="el-icon-upload el-icon--right"></i></el-button>
            </el-form-item>

            <el-form-item>
              <el-input
                clearable
                v-model="searchKeyword"
                placeholder="请输入关键字"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="handleSearchClick"
              >
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog
      title="上传选单"
      :visible.sync="dialogVisible"
      width="45%"
      center
    >
      <el-row
        type="flex"
        justify="end"
        align="middle"
      >
        <el-col :span="4">
          档案名称
        </el-col>
        <el-col
          :span="8"
          type="flex"
          justify="end"
        >
          <el-card
            class="myCardBlock"
            shadow="never"
          >
            <span v-html="dialogData.fileName"></span>
          </el-card>
        </el-col>
        <el-col
          :span="rightColWidth"
          :offset="1"
        >
          <el-upload
            class="upload-demo"
            :file-list="fileList"
            :http-request="selectUploadFile"
            action=""
            v-if="!isDialogEditMode"
            ref="fileInput"
            accept=".apk"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-col>
      </el-row>

      <el-row
        type="flex"
        justify="end"
        align="middle"
      >
        <el-col :span="4">
          版本號
        </el-col>
        <el-col
          :span="8"
          type="flex"
          justify="end"
        >
          <el-card
            class="myCardBlock"
            shadow="never"
          >
            <span v-html="dialogData.version"></span>
          </el-card>
        </el-col>
        <el-col
          :span="rightColWidth"
          :offset="1"
        >
        </el-col>
      </el-row>

      <el-row
        type="flex"
        justify="end"
        align="middle"
      >
        <el-col :span="4">
        </el-col>
        <el-col
          :span="12"
          type="flex"
          justify="end"
        >
          <span>※自动根据档名获取版本号。</span>

        </el-col>
        <el-col
          :span="rightColWidth"
          :offset="1"
        >
        </el-col>
      </el-row>

      <el-row
        type="flex"
        justify="center"
        align="middle"
      >
        <el-col
          :span="17"
          type="flex"
          justify="start"
          :offset="1"
        >
          <el-input
            type="textarea"
            :rows="5"
            placeholder="更新叙述"
            v-model="dialogData.description"
          >
          </el-input>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-table
      :data="tableData"
      style="width: 100%"
      header-align="center"
    >
      <el-table-column
        prop="filename"
        label="APK名称"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="version"
        label="版本号"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="此版本日期"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="is_visible"
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_visible"
            active-value="1"
            inactive-value="0"
            @change="visibleChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="修改叙述"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            icon="el-icon-edit-outline"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next"
      :current-page="pageData.currentPage"
      :total="pageData.totalPage"
      :page-size="pageData.PAGE_SIZE"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { uploadAPK, getAppFileList, setAppVisible, setAppRemark } from '@/api/admin/appfile'
import SparkMD5 from 'spark-md5'
export default {
  data() {
    return {
      pageData: {
        currentPage: 1,
        totalPage: 1,
        PAGE_SIZE: 10 // 一頁幾筆
      },
      dialogData: {
        id: '',
        fileName: '&nbsp',
        version: '&nbsp',
        description: ''
      },
      fileList: [],
      tableData: [],
      searchKeyword: '',
      dialogVisible: false,
      isDialogEditMode: false,
      fileInput: null,
      loading: null,
      loadSuccess: 0,
      CHUNK_SIZE: 2 * 1024 * 1024 // 以2MB為一個分片
    }
  },
  mounted () {
    this.callGetAppFileList()
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.callGetAppFileList()
      }
    }
  },
  methods: {
    startLoading(text = '') {
      this.loading = this.$loading({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
        text
      })
    },
    setUploadLoadingText(index, maxCount) {
      this.loading.text = `上傳中:${Math.floor((index / maxCount) * 100)}%`
    },
    closeLoading() {
      this.loading.close()
    },
    handleSearchClick() {
      this.pageData.currentPage = 1
      this.callGetAppFileList()
    },
    handleUploadClick() {
      this.clearDialogData()
      this.dialogVisible = true
      this.isDialogEditMode = false
    },
    handlePageChange(newPage) {
      this.pageData.currentPage = newPage
      this.callGetAppFileList()
    },
    handleEdit(index, row) {
      this.clearDialogData()
      this.openEdit(row)
    },
    openEdit(row) {
      this.dialogData = {
        id: row.id,
        fileName: row.filename,
        version: row.version,
        description: row.remark
      }
      this.isDialogEditMode = true
      this.dialogVisible = true
    },
    callGetAppFileList() {
      this.startLoading()
      getAppFileList({
        keyword: this.searchKeyword,
        page: this.pageData.currentPage,
        pageSize: this.pageData.PAGE_SIZE
      })
        .then(res => {
          this.tableData = res.data
          this.pageData.currentPage = res.currentPage
          this.pageData.totalPage = res.count
        })
        .finally(() => {
          this.closeLoading()
        })
    },
    visibleChange(row) {
      if (row.is_visible == '0') {
        row.is_visible = '1'
        this.$message({
          message: '已上架,無法下架',
          type: 'warning'
        })
        return
      }
      this.startLoading()
      setAppVisible(row.id, { is_visible: row.is_visible }).then(res => {
        if (this.$resStatus.OK === res.status) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        } else {
          throw res
        }
      }).catch(err => {
        if (row.is_visible == '0') {
          row.is_visible = '1'
        } else {
          row.is_visible = '0'
        }
        this.$message({
          message: '修改失败',
          type: 'error'
        })
        console.error('err:', err)
      }).finally(() => {
        this.closeLoading()
      })
    },
    async dialogSubmit() {
      if (this.dialogData.id === '' && this.fileInput === null) {
        this.$message({
          message: '請先上傳檔案',
          type: 'error'
        })
        return
      }
      this.startLoading()
      if (this.fileInput) {
        const hash = await this.getFileInputHash()
        await this.uploadFile(hash)
      }
      if (this.dialogData.id !== '') {
        await this.callSetAppRemark(this.dialogData.id, this.dialogData.description, this.fileInput === null)
      }
      this.closeLoading()
    },
    callSetAppRemark(id, text, isShowMessage = true) {
      return setAppRemark(id, text).then(res => {
        if (this.$resStatus.OK === res.status) {
          if (isShowMessage) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
          this.dialogVisible = false
        } else {
          this.$message({
            message: `修改失败 ${res.message}`,
            type: 'error'
          })
        }
      })
    },
    selectUploadFile({ file }) {
      if (!file) {
        return
      }
      if (
        file.name.indexOf('.apk') !== -1
      ) {
      } else {
        this.$message({
          message: '上传格式错误,仅支援apk',
          type: 'error'
        })
        return
      }

      // 檢查檔名格式
      this.fileInput = file
      this.dialogData.fileName = file.name
      this.dialogData.version = '&nbsp'
      file.name.split('_').forEach(it => {
        if (it.indexOf('.apk') != -1) {
          const version = it.replace('.apk', '')
          const pointNum = (version.split('.')).length - 1
          const isOK = version.split('.').every(it => {
            return /^-?\d+$/.test(it)
          })
          if (pointNum === 2 && isOK) {
            this.dialogData.version = version
          }
        }
      })
      const dashCount = (file.name.split('_')).length - 1
      if (this.dialogData.version === '&nbsp' || dashCount > 1) {
        this.dialogData.fileName = '&nbsp'
        this.dialogData.version = '&nbsp'
        this.fileInput = null
        this.$message({
          message: '版本号格式須為 名稱_1.10.1.apk or 名稱_1.2.1.apk',
          type: 'error'
        })
      }
    },
    uploadFile(md5File) {
      return new Promise((resolve, reject) => {
        const file = this.fileInput
        const name = file.name // 檔名
        const size = file.size // 總大小
        const shardCount = Math.ceil(size / this.CHUNK_SIZE) // 總片數

        const uploadList = []
        for (let i = 0; i < shardCount - 1; ++i) {
          const start = i * this.CHUNK_SIZE // 當前分片開始下標
          const end = Math.min(size, start + this.CHUNK_SIZE)// 結束下標
          const uploadFile = new File([file.slice(start, end)], name)
          const uploadAPKObj = uploadAPK({
            upload: uploadFile,
            blob_num: i + 1,
            total_blob_num: shardCount,
            checksum: ''
          })
          uploadAPKObj.API.then((res) => {
            if (this.$resStatus.OK === res.status || this.$resStatus.OK2 === res.status) {
              this.setUploadLoadingText(this.loadSuccess++, shardCount)
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              uploadList.forEach(it => {
                it.source.cancel()
              })
              resolve('cancel')
            }
          })
          uploadList.push(uploadAPKObj)
        }
        this.loadSuccess = 0
        this.setUploadLoadingText(this.loadSuccess, shardCount)
        // 最後一個chunk要傳md5
        Promise.all(uploadList.map(it => it.API)).then((result) => {
          const start = (shardCount - 1) * this.CHUNK_SIZE // 當前分片開始下標
          const end = Math.min(size, start + this.CHUNK_SIZE)// 結束下標
          const uploadAPKObj = uploadAPK({
            upload: new File([file.slice(start, end)], name),
            blob_num: shardCount,
            total_blob_num: shardCount,
            checksum: md5File
          })
          uploadAPKObj.API.then((res) => {
            if (this.$resStatus.OK === res.status) {
              this.$message({
                message: '上傳成功',
                type: 'success'
              })
              this.dialogData.id = res.data.id
              this.dialogData.fileName = res.data.filename
              this.dialogData.version = res.data.version
              resolve()
            } else {
              this.$message({
                message: `上傳失败 ${res.message}`,
                type: 'error'
              })
              resolve(res.message)
            }
          })
        })
      })
    },
    getFileInputHash() {
      return new Promise((resolve) => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        const file = this.fileInput
        const chunks = Math.ceil(file.size / this.CHUNK_SIZE)
        let currentChunk = 0
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()

        fileReader.onload = (e) => {
          spark.append(e.target.result) // Append array buffer
          currentChunk++

          if (currentChunk < chunks) {
            this.loadNext(currentChunk, blobSlice, file, fileReader)
          } else {
            const md5Hash = spark.end()
            resolve(md5Hash)
          }
        }

        fileReader.onerror = function () {
          console.warn('oops, something went wrong.')
        }
        this.loadNext(currentChunk, blobSlice, file, fileReader)
      })
    },
    loadNext(currentChunk, blobSlice, file, fileReader) {
      const start = currentChunk * this.CHUNK_SIZE
      const end = ((start + this.CHUNK_SIZE) >= file.size) ? file.size : start + this.CHUNK_SIZE
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    },
    clearDialogData() {
      this.fileInput = null
      this.dialogData = {
        id: '',
        fileName: '&nbsp',
        version: '&nbsp',
        description: ''
      }
    }
  },
  computed: {
    rightColWidth() {
      if (!this.isDialogEditMode) {
        return 7
      } else {
        return 4
      }
    }
  }
}
</script>

<style lang="scss">
#OnlineOfficial {
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-dialog__wrapper {
    .el-col {
      display: flex;
      align-items: center;
    }
  }
  .el-upload-list {
    display: none;
  }
  .el-dialog {
    max-width: 580px;
    min-width: 580px;
  }
}
</style>

<style lang="scss" scope>
#OnlineOfficial {
  .myCardBlock {
    width: 100%;
    .el-card__body {
      padding: 10px;
    }
  }
  .el-row {
    margin-bottom: 20px;
  }
}
</style>
